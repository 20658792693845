import React from 'react';
import NotFoundPageModel from './Models/NotFoundPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import ContentArea from '../Shared/ContentArea/ContentArea';
import ContentContainer from '../Shared/ContentContainer/ContentContainer';
import { styled, theme } from '../Theme';

function NotFoundPage() {
  const { contentArea } = useCurrentPage<NotFoundPageModel>();

  return (
    <NotFoundPageContainer>
      <ContentContainer>
        <ContentArea childItems={contentArea} />
      </ContentContainer>
    </NotFoundPageContainer>
  );
}

export default NotFoundPage;

const NotFoundPageContainer = styled.div({
  backgroundColor: theme.lightAqua,
});
